// src/components/MainFooter.js

import React from 'react';
import './MainFooter.css';

const MainFooter = () => {
  return (
    <footer className="main-footer">
      <p>&copy; {new Date().getFullYear()} TrackMyDocs. All rights reserved.</p>
    </footer>
  );
};

export default MainFooter;
