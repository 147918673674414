import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import DashboardHeader from '../components/DashboardHeader';
import Sidebar from '../components/Sidebar';
import DashboardFooter from '../components/DashboardFooter';
import './InvoiceInfo.css';
import Modal from '../components/Modal';

const InvoiceInfo = () => {
  const { invoice_number } = useParams();
  const navigate = useNavigate();

  //state to fetch invoice details for iminfo-detail-container div
  const [invoiceDetails, setInvoiceDetails] = useState(null);

  //state to fetch files from S3 bucket and generate documents in iminfo-documents div
  const [files, setFiles] = useState([]);
  const [loadingFiles, setLoadingFiles] = useState(true);
  const [fileError, setFileError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const [loadingInvoice, setLoadingInvoice] = useState(true);
  const [error, setError] = useState(null);

  // State to fetch and display invoice data in the iminfo-display div
  const [invoiceData, setInvoiceData] = useState([]);  // Initialize as an empty array to handle multiple records
  const [loadingInvoiceData, setLoadingInvoiceData] = useState(true);
  const [invoiceDataError, setInvoiceDataError] = useState(null);

  //State to display invoice totals
  const [invoiceTotals, setInvoiceTotals] = useState({
    totalCharges: 0,
    totalVatAmount: 0,
    totalNetAmount: 0,
    totalInvoiceAmount: 0
  });
  const [loadingTotals, setLoadingTotals] = useState(true);
  const [totalsError, setTotalsError] = useState(null);

  //State for Modal Visibility
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  }; 
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // State to manage form data for adding additional fees
  const [additionalFeeData, setAdditionalFeeData] = useState({
    invoiceType: '',
    charges: '',
    vatAmount: '',
    totalNetAmount: '',
    invoiceTotal: ''
  });

  // Effect to auto-calculate VAT, Total Net Amount, and Invoice Total based on charges
  useEffect(() => {
    const charges = parseFloat(additionalFeeData.charges) || 0; // Handle NaN for empty inputs
    const vatAmount = charges * 0.20; // 20% VAT
    setAdditionalFeeData(prev => ({
      ...prev,
      vatAmount: vatAmount.toFixed(2), // Formats to 2 decimal places
      totalNetAmount: charges.toFixed(2),
      invoiceTotal: (charges + vatAmount).toFixed(2)
    }));
  }, [additionalFeeData.charges]);

  // Function to submit the additional fee data to the backend
  const submitAdditionalFee = async () => {
    const dataToSend = {
      invoice_number: invoiceDetails.invoice_number,
      our_ref: invoiceDetails.our_ref,
      invoice_type: additionalFeeData.invoiceType,
      charges: additionalFeeData.charges,
      vat_amount: additionalFeeData.vatAmount,
      total_net_amount: additionalFeeData.totalNetAmount,
      invoice_total: additionalFeeData.invoiceTotal,
    };

    try {
      const response = await axios.post(
        'https://app.trackmydocs.cloud/api/invoices/add-fee/',
        dataToSend,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 201) {
        alert('Additional fee added successfully!');
        handleCloseModal(); // Only close modal after successful submission
        fetchInvoiceData(localStorage.getItem('access_token')); // Fetch the updated invoice data to refresh the table
        window.location.reload(); // Reload the page to fetch updated data
      }
    } catch (error) {
      console.error('Failed to add additional fee:', error.response?.data || error.message);
      alert('Failed to add additional fee: ' + (error.response?.data.error || error.message));
    }
  };
  
  // Fetch invoice details for iminfo-detail-container div
  useEffect(() => {
    const fetchInvoiceDetails = async () => {
      const token = localStorage.getItem('access_token');

      if (!token) {
        navigate('/signin');
        return;
      }

      try {
        const response = await axios.get(`https://app.trackmydocs.cloud/api/invoices/disp-invoice/${invoice_number}/`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Cache-Control': 'no-cache',
            'Pragma': 'no-cache',
            'Expires': '0'
          },
        });

        if (response.status === 200) {
          setInvoiceDetails(response.data);
        } else {
          setError('Invoice not found');
        }
        setLoadingInvoice(false);
      } catch (error) {
        setError('Error fetching invoice details');
        setLoadingInvoice(false);
      }
    };

    fetchInvoiceDetails();
  }, [invoice_number, navigate]);

  // Function to fetch combined invoice entries for the iminfo-display div
  const fetchInvoiceData = useCallback(async (token) => {
    setLoadingInvoiceData(true);
    setInvoiceDataError(null);

    try {
      const response = await axios.get(
        `https://app.trackmydocs.cloud/api/invoices/id-table/${invoice_number}/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200 && response.data.length > 0) {
        setInvoiceData(response.data);  // Set the array of invoice data
      } else {
        setInvoiceDataError('No invoice details found.');
      }

      setLoadingInvoiceData(false);
    } catch (error) {
      setInvoiceDataError('Error fetching invoice data. Please try again later.');
      setLoadingInvoiceData(false);
    }
  }, [invoice_number]);

  // Fetch invoice data when the component loads or invoice_number changes
  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      fetchInvoiceData(token);
    }
  }, [invoice_number, fetchInvoiceData]);

  //Function to fetch data for iminfo-total div
  useEffect(() => {
    const fetchInvoiceTotals = async () => {
      setLoadingTotals(true);
      setTotalsError(null);
  
      const token = localStorage.getItem('access_token');
      if (!token) {
        navigate('/signin');
        return;
      }
  
      try {
        const response = await axios.get(`https://app.trackmydocs.cloud/api/invoices/invoice-total/${invoice_number}/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (response.status === 200) {
          setInvoiceTotals({
            totalCharges: response.data.total_charges,
            totalVatAmount: response.data.total_vat_amount,
            totalNetAmount: response.data.total_net_amount,
            totalInvoiceAmount: response.data.total_invoice_amount
          });
        } else {
          setTotalsError('Failed to fetch invoice totals');
        }
      } catch (error) {
        setTotalsError('Error fetching invoice totals');
      }
  
      setLoadingTotals(false);
    };
  
    fetchInvoiceTotals();
  }, [invoice_number, navigate]);

  // Function to fetch files from the S3 bucket
  const fetchFiles = useCallback(async (token) => {
    setLoadingFiles(true);
    setFileError(null);

    try {
      const case_number = invoiceDetails?.our_ref.replace(/\//g, '-');
      if (!case_number) {
        throw new Error('Our Ref (case number) is not available');
      }
      
      const response = await axios.get(
        `https://app.trackmydocs.cloud/api/documents/list-invoice-files/${case_number}/`, 
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200 && response.data.files.length > 0) {
        setFiles(response.data.files);
      } else {
        setFileError('No invoice documents found.');
      }

      setLoadingFiles(false);
    } catch (error) {
      setFileError('Error fetching files. Please try again later.');
      setLoadingFiles(false);
    }
  }, [invoiceDetails]);

  // Fetch files when invoiceDetails are available
  useEffect(() => {
    if (invoiceDetails) {
      const token = localStorage.getItem('access_token');
      if (token) {
        fetchFiles(token);
      }
    }
  }, [invoiceDetails, fetchFiles]);

  // "Generate Invoice Document" button to generate documents in S3 bucket
  const handleGenerateInvoiceDoc = async () => {
    const token = localStorage.getItem('access_token');

    if (!token) {
      navigate('/signin');
      return;
    }

    try {
      const response = await axios.post(
        `https://app.trackmydocs.cloud/api/documents/gen-invoice-doc/`, 
        { invoice_number },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.status === 200 || response.status === 201) {
        setSuccessMessage(response.data.message || 'Invoice document generated successfully!');
        alert(response.data.message || 'Invoice document generated successfully!');
        fetchFiles(token);  // Refresh the file list after the document is created
      } else {
        alert('Unexpected response from the server.');
      }
    } catch (error) {
      console.error('Error generating document:', error);
      alert('Error generating document. Please try again later.');
    }
  };

  if (loadingInvoice) return <div>Loading invoice details...</div>;
  if (error) return <div>{error}</div>;

  return (
    <div className="iminfo-page-container">
      <DashboardHeader />
      <Sidebar />

      <div className="iminfo-main-content">
        <div className="iminfo-content-area">
          <div className="iminfo-detail-container">
            <h2>Invoice #{invoice_number}</h2>
            <p>Client: {invoiceDetails.client}</p>
            <p>Status: {invoiceDetails.status}</p>
            <p>Invoice Date: {invoiceDetails.invoice_date}</p>
            <p>Property Address: {invoiceDetails.property_address}</p>
            <p>Our Ref: {invoiceDetails.our_ref}</p>
            <p>VAT No: {invoiceDetails.vat_no}</p>
          </div>

          <div className="iminfo-documents">
            <h3 className="iminfo-h3">Invoice Documents</h3>

            <button 
              className="iminfo-generate-documents-btn" 
              onClick={handleGenerateInvoiceDoc}
            >
              Generate Invoice Document
            </button>

            {successMessage && <p className="iminfo-success-message">{successMessage}</p>}

            {loadingFiles ? (
              <p>Loading files...</p>
            ) : fileError ? (
              <p>{fileError}</p>
            ) : (
              <table className="iminfo-files-table">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Owner</th>
                    <th>Recently Modified</th>
                  </tr>
                </thead>
                <tbody>
                  {files.map((file, index) => (
                    <tr key={index}>
                      <td>
                        <a href={file.url} target="_blank" rel="noopener noreferrer">
                          {file.name}
                        </a>
                      </td>
                      <td>{invoiceDetails.client}</td>
                      <td>{file.lastModified}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
          
          {/* Invoice Total */}
          <div className="iminfo-total">
            <h3 className="iminfo-h3">Invoice Total</h3>
            {loadingTotals ? (
              <p>Loading totals...</p>
            ) : totalsError ? (
              <p>{totalsError}</p>
            ) : (
              <div>
                <p>Total Charges: £{invoiceTotals.totalCharges}</p>
                <p>Total VAT Amount: £{invoiceTotals.totalVatAmount}</p>
                <p>Total Net Amount: £{invoiceTotals.totalNetAmount}</p>
                <p>Total Invoice Amount: £{invoiceTotals.totalInvoiceAmount}</p>
              </div>
            )}
          </div>

          {/* Invoice Display */}
            <div className="iminfo-display">
              <div className="iminfo-display-header">
                <h3 className="iminfo-id-h3">Invoice Details</h3>
                <button className="iminfo-add-fee-btn" onClick={handleOpenModal}>
                  Create Other Fee
                </button>
              </div>
              {/* Modal component */}
              <Modal 
                isOpen={isModalOpen} 
                onClose={handleCloseModal}
                invoiceNumber={invoiceDetails?.invoice_number}
                ourRef={invoiceDetails?.our_ref}
                submitAdditionalFee={submitAdditionalFee} // Pass the function here
              >
                {/* Form fields and submit logic are passed as children */}
                <form onSubmit={(e) => {
                  e.preventDefault(); // Prevent the default form submission behavior
                  submitAdditionalFee(); // Call the function passed as a prop
                }}>
                  <input 
                    className="tmd_modal_input" 
                    placeholder="Invoice Type" 
                    type="text"
                    value={additionalFeeData.invoiceType}
                    onChange={e => setAdditionalFeeData({...additionalFeeData, invoiceType: e.target.value})}
                  />
                  <input 
                    className="tmd_modal_input" 
                    placeholder="Charges" 
                    type="number"
                    value={additionalFeeData.charges}
                    onChange={e => setAdditionalFeeData({...additionalFeeData, charges: e.target.value})}
                  />
                  <input 
                    className="tmd_modal_input" 
                    placeholder="VAT Amount" 
                    type="text"
                    value={`£${additionalFeeData.vatAmount}`}
                    readOnly
                  />
                  <input 
                    className="tmd_modal_input" 
                    placeholder="Total Net Amount" 
                    type="text"
                    value={`£${additionalFeeData.totalNetAmount}`}
                    readOnly
                  />
                  <input 
                    className="tmd_modal_input" 
                    placeholder="Invoice Total" 
                    type="text"
                    value={`£${additionalFeeData.invoiceTotal}`}
                    readOnly
                  />
                </form>
              </Modal>


              {loadingInvoiceData ? (
                <p>Loading invoice details...</p>
              ) : invoiceDataError ? (
                <p>{invoiceDataError}</p>
              ) : (
                <table className="iminfo-id-table">
                  <thead>
                    <tr>
                      <th>Invoice Number</th>
                      <th>Our Ref</th>
                      <th>Invoice Type</th>
                      <th>Charges (£)</th>
                      <th>VAT Amount (£)</th>
                      <th>Total Net Amount (£)</th>
                      <th>Invoice Total (£)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoiceData.map((invoice, index) => (
                      <tr key={index}>
                        <td>{invoice.invoice_number}</td>
                        <td>{invoice.our_ref}</td>
                        <td>{invoice.invoice_type}</td>
                        <td>{invoice.charges}</td>
                        <td>{invoice.vat_amount}</td>
                        <td>{invoice.total_net_amount}</td>
                        <td>{invoice.invoice_total}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
            
        </div>
      </div>
      <DashboardFooter />
    </div>
  );
};

export default InvoiceInfo;
