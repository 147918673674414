import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import DashboardHeader from './DashboardHeader';
import Sidebar from './Sidebar';
import DashboardFooter from './DashboardFooter';
import './Invoices.css';

const Invoices = () => {
  const { case_number } = useParams(); // Get the case number from the URL
  const [invoiceDetails, setInvoiceDetails] = useState({
    invoice_date: '',
    invoice_number: '',
    property_address: '',
    our_ref: '',
    client: '',
    vat: 0.2, // Default VAT rate
  });
  const [invoiceType, setInvoiceType] = useState(''); // State for invoice type dropdown
  const [charges, setCharges] = useState(''); // State for charges
  const [vatApplicable, setVatApplicable] = useState(true); // State for VAT toggle
  const navigate = useNavigate();  // Use the navigate hook

  const invoiceTypeChargesMap = useMemo(() => ({
    'S21 Claim': 120.00,
    'S21 Notice': 90.00,
    'S48 Notice': 110.00,
    'S8 Claim - Rent Arrears': 130.00,
    'S8 Notice': 95.00,
  }), []); // Empty array as the dependencies

  const [invoices, setInvoices] = useState([]); // New state to store the array of invoices

  // State to control dropdown visibility
  const [showDropdown, setShowDropdown] = useState(false);
  const handleDropdownToggle = () => {
    setShowDropdown(prevState => !prevState);
  };
  // Inside your component
  const dropdownRef = useRef(null);
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);  // Close the dropdown if clicking outside
    }
  };

  //Handler to click outside 
  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Fetch invoice details for invoice form div invoice-details
  useEffect(() => {
    const fetchInvoiceDetails = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.get(`/api/invoices/gen-invoice/${case_number}/`, {
          headers: { 'Authorization': `Bearer ${token}` },
        });

        if (response.status === 200 && response.data.length > 0) {
          const sortedInvoices = response.data.sort((a, b) => {
            // Sort by invoice number, assuming invoice numbers can be converted to integers for ordering
            return parseInt(a.invoice_number) - parseInt(b.invoice_number);
          });
          setInvoices(sortedInvoices); // Set the sorted array of invoices
          // Set default invoice details using the first invoice or a non-new invoice as default if exists
          const defaultInvoice = sortedInvoices.find(inv => !inv.is_new_invoice) || sortedInvoices[0];
          if (defaultInvoice) {
            setInvoiceDetails({
              invoice_date: defaultInvoice.invoice_date,
              invoice_number: defaultInvoice.invoice_number,
              property_address: defaultInvoice.property_address,
              our_ref: defaultInvoice.our_ref,
              client: defaultInvoice.client,
              vat: defaultInvoice.vat
            });
          }
        } else {
          console.error('Failed to fetch invoice details:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching invoice details:', error);
        alert('Error fetching invoice details. Please try again.');
      }
    };

    fetchInvoiceDetails();
  }, [case_number]);

  
  
  

  useEffect(() => {
    // Update charges when invoice type changes
    if (invoiceType && invoiceTypeChargesMap[invoiceType]) {
      setCharges(invoiceTypeChargesMap[invoiceType].toFixed(2));
    }
  }, [invoiceType, invoiceTypeChargesMap]);

  const handleChargesChange = (e) => {
    setCharges(e.target.value);
  };

  const handleVatToggle = () => {
    setVatApplicable(!vatApplicable);
  };

  const calculateVat = () => {
    const vatAmount = vatApplicable ? (parseFloat(charges) * invoiceDetails.vat).toFixed(2) : '0.00';
    return vatAmount;
  };

  const calculateTotal = () => {
    const vatAmount = parseFloat(calculateVat());
    const total = parseFloat(charges) + vatAmount;
    return total.toFixed(2);
  };

  // Handler to generate invoice
  const handleGenerateInvoice = async (event) => {
    event.preventDefault();

    const token = localStorage.getItem('access_token');
    if (!token) {
      console.error('No token available for the request!');
      alert('You are not authenticated. Please log in again.');
      return;
    }

    const isNewInvoiceSelected = invoiceDetails.is_new_invoice; // Check if the user chose "Create New Invoice Number"
    
    let forceNewInvoice = false;

    if (isNewInvoiceSelected) {
      // User chose to create a new invoice
      forceNewInvoice = true;
    } else if (!invoiceDetails.invoice_number) {
      // No invoice number selected
      alert("Please select an invoice number.");
      return;
    }

    try {
      const response = await fetch('https://app.trackmydocs.cloud/api/invoices/generate-invoice/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          case_number: case_number,
          invoice_date: invoiceDetails.invoice_date,
          invoice_number: invoiceDetails.invoice_number,  // Existing or new invoice number
          property_address: invoiceDetails.property_address,
          our_ref: invoiceDetails.our_ref,
          client: invoiceDetails.client,
          invoice_type: invoiceType,
          charges: parseFloat(charges),
          vat_amount: parseFloat(calculateVat()),
          total_net_amount: parseFloat(charges),
          invoice_total: parseFloat(calculateTotal()),
          vat_no: "458 0158 86",
          force_new_invoice: forceNewInvoice  // Pass the flag to the backend
        })
      });

      if (response.ok) {
        alert('Invoice generated successfully!');
        navigate('/invoice-management');  // Redirect after success
      } else {
        console.error('Failed to generate invoice:', response.statusText);
        alert('Failed to generate invoice.');
      }
    } catch (error) {
      console.error('Error generating invoice:', error);
      alert('Failed to generate invoice.');
    }
  };

  
  
  return (
    <div className="invoices-page-container">
      <DashboardHeader />
      <Sidebar />
      <div className="invoices-main-content">
        <div className="invoices-detail-documents">
          <h3 className="case-detail-h3">Landlord Advice Fee</h3>
          
          <div className="invoice-details">
            <label>Invoice Date:</label>
            <input type="text" className="invoice-input" value={invoiceDetails.invoice_date} readOnly />

            <label>Invoice Number:</label>
            <div className="in-num-dropdown-container" ref={dropdownRef}>
              <button
                id="dropdownDefaultButton"
                className="in-num-dropdown-button"
                type="button"
                onClick={handleDropdownToggle}  // This toggles the dropdown
              >
                {invoiceDetails.invoice_number || "Select Invoice Number"}
                <svg className="w-4 h-4 ml-2" fill="none" viewBox="0 0 20 20">
                  <path d="M5.5 7.5L10 12l4.5-4.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
              </button>
              <div className={`in-num-dropdown-content ${showDropdown ? 'show' : ''}`}>
                {invoices.map((invoice, index) => (
                  <button key={index} onClick={() => {
                    setInvoiceDetails({...invoiceDetails, invoice_number: invoice.invoice_number, is_new_invoice: invoice.is_new_invoice});
                    setShowDropdown(false);  // Also close dropdown on selection
                  }}>
                    {invoice.invoice_number}
                  </button>
                ))}
              </div>
            </div>


            <label>VAT No:</label>
            <input type="text" className="invoice-input" value="458 0158 86" readOnly />

            <label>Property Address:</label>
            <input type="text" className="invoice-input" value={invoiceDetails.property_address} readOnly />

            <label>Our Ref:</label>
            <input type="text" className="invoice-input" value={invoiceDetails.our_ref} readOnly />

            <label>Client:</label>
            <input type="text" className="invoice-input" value={invoiceDetails.client} readOnly />

            <label>Invoice Type:</label>
            <select
              value={invoiceType}
              onChange={(e) => setInvoiceType(e.target.value)}
              className="invoice-input"
            >
              <option value="">Select Invoice Type</option>
              <option value="S21 Claim">S21 Claim</option>
              <option value="S21 Notice">S21 Notice</option>
              <option value="S48 Notice">S48 Notice</option>
              <option value="S8 Claim - Rent Arrears">S8 Claim - Rent Arrears</option>
              <option value="S8 Notice">S8 Notice</option>
            </select>

            <label>Charges:</label>
            <input 
              type="text" 
              className="invoice-input" 
              value={`£${charges}`} 
              onChange={handleChargesChange} 
            />

            <label className="vat-toggle">
              <input 
                type="checkbox" 
                checked={vatApplicable} 
                onChange={handleVatToggle} 
              />
              Apply VAT
            </label>

            <label>VAT:</label>
            <input 
              type="text" 
              className="invoice-input" 
              value={`£${calculateVat()}`} 
              readOnly 
            />

            <label>Total Net Amount:</label>
            <input 
              type="text" 
              className="invoice-input" 
              value={`£${charges}`} 
              readOnly 
            />

            <label>Invoice Total:</label>
            <input 
              type="text" 
              className="invoice-input" 
              value={`£${calculateTotal()}`} 
              readOnly 
            />
          </div>

          <button 
            className="invoice-generate-btn"
            onClick={handleGenerateInvoice}
          >
            Create Invoice
          </button>
        </div>
      </div>
      <DashboardFooter />
    </div>
  );
};

export default Invoices;
