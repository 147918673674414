import React, { useState } from 'react';
import '../components/CaseDetail_Modal.css'; // Ensure the CSS is correctly linked

const CaseDetailModal = ({ isOpen, onClose, onSubmit }) => {
  const [title, setTitle] = useState('');
  const [givenName, setGivenName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [contactNumber, setContactNumber] = useState('');

  // Handler for form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Gather the form data
    const landlordData = {
      addland_title: title,
      addland_given_name: givenName,
      addland_surname: surname,
      addland_email: email,
      addland_contact_number: contactNumber,
    };

    // Call the onSubmit function passed from CaseDetail.js with the form data
    onSubmit(landlordData);

    // Optionally close the modal after submission
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="case-detail-modal_overlay">
      <div className="case-detail-modal_content">
        <button className="case-detail-modal_close_button" onClick={onClose}>
          &times; {/* Stylish close button */}
        </button>
        <h2>Add Landlord</h2> {/* Added heading */}
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            className="case-detail-modal_input"
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)} // Update the state
          />
          <input
            type="text"
            className="case-detail-modal_input"
            placeholder="Given Name"
            value={givenName}
            onChange={(e) => setGivenName(e.target.value)} // Update the state
          />
          <input
            type="text"
            className="case-detail-modal_input"
            placeholder="Surname"
            value={surname}
            onChange={(e) => setSurname(e.target.value)} // Update the state
          />
          <input
            type="email"
            className="case-detail-modal_input"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)} // Update the state
          />
          <input
            type="text"
            className="case-detail-modal_input"
            placeholder="Contact Number"
            value={contactNumber}
            onChange={(e) => setContactNumber(e.target.value)} // Update the state
          />
          <button type="submit" className="case-detail-modal_submit_button">
            Add Landlord
          </button>
        </form>
      </div>
    </div>
  );
};

export default CaseDetailModal;
