import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DashboardHeader from '../components/DashboardHeader';
import Sidebar from '../components/Sidebar';
import DashboardFooter from '../components/DashboardFooter';
import './Dashboard.css';
import { useNavigate, Link } from 'react-router-dom';

const Dashboard = () => {
  const navigate = useNavigate();
  const [cases, setCases] = useState([]);
  const [searchInput, setSearchInput] = useState(''); // State for storing the search input

  useEffect(() => {
    const fetchCases = async () => {
      const token = localStorage.getItem('access_token');
      if (!token) {
        console.error('No JWT token found, redirecting to login.');
        navigate('/signin');
        return;
      }

      try {
        const response = await axios.get('https://app.trackmydocs.cloud/api/clients/cases/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCases(response.data);
      } catch (error) {
        console.error('Error fetching cases:', error);
        if (error.response && error.response.status === 401) {
          navigate('/signin');
        }
      }
    };

    fetchCases();
  }, [navigate]); // Ensure fetchCases is called only when navigate changes

  const handleSearchChange = (event) => {
    setSearchInput(event.target.value.toLowerCase());
  };

  // Function to filter cases based on the search input
  const filteredCases = cases.filter(
    caseItem =>
      caseItem.case_number.toLowerCase().includes(searchInput) ||
      (caseItem.person && caseItem.person.toLowerCase().includes(searchInput))
  );

  return (
    <div className="dashboard-container">
      <DashboardHeader />
      <Sidebar />
      <div className="main-content">
        <div className="content-area">
          <div className="recent-cases">
            <div className="recent-cases-header">
              <h2>Recent Cases</h2>
              <input
                type="text"
                className="search-bar"
                placeholder="Search cases..."
                onChange={handleSearchChange}
                value={searchInput}
              />
              <button 
                className="create-case-btn" 
                onClick={() => navigate('/new-client')}
              >
                Create Case
              </button>
            </div>
            <table className="cases-table">
              <thead>
                <tr>
                  <th>Case Number</th>
                  <th>Person</th>
                  <th>Client Type</th>
                  <th>Tenant</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {filteredCases.length > 0 ? (
                  filteredCases.map((caseItem) => {
                    const sanitisedCaseNumber = caseItem.case_number.replace(/\//g, '-');
                    return (
                      <tr key={caseItem.id}>
                        <td>
                          <Link to={`/cases/${sanitisedCaseNumber}`}>
                            {caseItem.case_number}  {/* Display original case number with slashes */}
                          </Link>
                        </td>
                        <td>{caseItem.person}</td>
                        <td>{caseItem.client_type}</td>
                        <td>{caseItem.tenant || '-'}</td>
                        <td>{caseItem.status}</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="5">No cases found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <DashboardFooter />
    </div>
  );
};

export default Dashboard;
