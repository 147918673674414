// src/components/DashboardFooter.js

import React from 'react';
import './DashboardFooter.css';

const DashboardFooter = () => {
  return (
    <footer className="dashboard-footer">
      © 2024 TrackMyDocs. All rights reserved.
    </footer>
  );
};

export default DashboardFooter;
