import React, { useEffect } from 'react';
import './Modal.css'; // Ensure your CSS file is linked correctly

// Modal.js
const Modal = ({ isOpen, onClose, children, invoiceNumber, ourRef, submitAdditionalFee }) => {
    // Log the submit function to check if it's being passed correctly
    useEffect(() => {
        console.log(submitAdditionalFee);  // Check if the function is passed correctly
    }, [submitAdditionalFee]);
    
    useEffect(() => {
      const footer = document.querySelector('.dashboard-footer');
      if (isOpen) {
        footer.classList.add('tmd_modal_blur_effect');
      } else {
        footer.classList.remove('tmd_modal_blur_effect');
      }
    }, [isOpen]);
  
    if (!isOpen) return null;
  
    return (
      <div className="tmd_modal_overlay">
        <div className="tmd_modal_content" onClick={e => e.stopPropagation()}>
          <button className="tmd_modal_close_button" onClick={onClose}>X</button>
          <div style={{ padding: 20 }}>
            <h2>Add Additional Fee</h2>
            <form onClick={(e) => e.stopPropagation()} onSubmit={(e) => {
                e.preventDefault(); // Stop form reload
                submitAdditionalFee(); // Call function
            }}>
              <input className="tmd_modal_input" value={invoiceNumber || ''} placeholder="Invoice Number" type="text" readOnly />
              <input className="tmd_modal_input" value={ourRef || ''} placeholder="Our Ref" type="text" readOnly />
              {children}
              <button className="tmd_modal_submit_button" type="submit">Submit</button>
            </form>
          </div>
        </div>
      </div>
    );
  };  

export default Modal;
