import React, { useState } from 'react';
import '../components/CaseDetail_Modal_AddTenant.css'; // Ensure the CSS is correctly linked

const CaseDetailModalTenant = ({ isOpen, onClose, onSubmit }) => {
  const [title, setTitle] = useState('');
  const [givenName, setGivenName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [contactNumber, setContactNumber] = useState('');

  // Handler for form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Gather the form data
    const tenantData = {
      addtent_title: title,
      addtent_given_name: givenName,
      addtent_surname: surname,
      addtent_email: email,
      addtent_contact_number: contactNumber,
    };

    // Call the onSubmit function passed from CaseDetail.js with the form data
    onSubmit(tenantData);

    // Optionally close the modal after submission
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="addtent_modal_overlay">
      <div className="addtent_modal_content">
        <button className="addtent_modal_close_button" onClick={onClose}>
          &times; {/* Stylish close button */}
        </button>
        <h2>Add Tenant</h2> {/* Added heading */}
        <form onSubmit={handleSubmit}>
          <input
            type="text"
            className="addtent_modal_input"
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <input
            type="text"
            className="addtent_modal_input"
            placeholder="Given Name"
            value={givenName}
            onChange={(e) => setGivenName(e.target.value)}
          />
          <input
            type="text"
            className="addtent_modal_input"
            placeholder="Surname"
            value={surname}
            onChange={(e) => setSurname(e.target.value)}
          />
          <input
            type="email"
            className="addtent_modal_input"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="text"
            className="addtent_modal_input"
            placeholder="Contact Number"
            value={contactNumber}
            onChange={(e) => setContactNumber(e.target.value)}
          />
          <button type="submit" className="addtent_modal_submit_button">
            Add Tenant
          </button>
        </form>
      </div>
    </div>
  );
};

export default CaseDetailModalTenant;
