// src/SignIn.js
import React, { useState } from 'react';
import axios from 'axios';
import './SignIn.css';
import MainFooter from './components/MainFooter'; // Import the MainFooter component
import { useNavigate } from 'react-router-dom';

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [role, setRole] = useState('user'); // Default role
  const [error, setError] = useState('');

  const navigate = useNavigate();

  // Intercept the request to log details
  axios.interceptors.request.use(
    (config) => {
      console.log('Making request to:', config.url);
      console.log('Request method:', config.method);
      console.log('Request data:', config.data);
      console.log('Request headers:', config.headers);
      return config;
    },
    (error) => {
      console.error('Error in request setup:', error);
      return Promise.reject(error);
    }
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Inline log before making the request
      console.log('Requesting:', 'https://app.trackmydocs.cloud/api/auth/login/');
      console.log('Request Payload:', { email, password, role });

      const response = await axios.post('https://app.trackmydocs.cloud/api/auth/login/', { email, password, role });

      if (response.status === 200) {
        console.log('Login successful');
        console.log('Response Data:', response.data);

        // Store the JWT tokens in local storage
        localStorage.setItem('access_token', response.data.access);
        localStorage.setItem('refresh_token', response.data.refresh);

        // Redirect to the dashboard using navigate instead of window.location.href
        navigate('/dashboard');
      } else {
        console.error('Login failed');
        setError('Login failed. Please check your credentials.');
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setError('An error occurred during login. Please try again.');
    }
  };

  return (
    <div className="signin-signin-container">
      <img src="/logo.png" alt="TrackMyDocs Logo" className="logo" />
      <h1>Sign in</h1>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleSubmit}>
      <div className="signin-role-selector">
          <label>
            <input type="radio" value="admin" checked={role === 'admin'} onChange={() => setRole('admin')} />
            Admin
          </label>
          <label>
            <input type="radio" value="user" checked={role === 'user'} onChange={() => setRole('user')} />
            User
          </label>
        </div>
        <input
          type="email"
          placeholder="Email Address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit" className="signin-button">Login</button>
      </form>
      <p>Are you new to TrackMyDocs? <a href="/register" className="signin-a">Register Here</a></p>
      <p className="signin-terms">By continuing, you agree to the TrackMyDocs Customer Agreement or other agreements for TrackMyDocs services, and the Privacy Notice. This site uses essential cookies. See our Cookie Notice for more information.</p>
      <MainFooter />
    </div>
  );
};

export default SignIn;
