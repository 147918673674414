import React, { useState, useEffect } from 'react';
import DashboardHeader from '../components/DashboardHeader';
import Sidebar from '../components/Sidebar';
import DashboardFooter from '../components/DashboardFooter';
import './NewClient.css'; // Assuming the same styles

const NewClient = () => {
    const [clientType, setClientType] = useState('Person');
    const [formData, setFormData] = useState({
        c_title: '',
        givenName: '',
        surname: '',
        email: '',
        contactNumber: '',
        gender: '',
        no: '',                // Field for house/building number for the landlord
        address: '',           // Existing address field
        city: '',              // New city field
        postcode: '',          // New postcode field
        property_address: '',  // This might store the full formatted address if needed
        t_title: '',
        t_givenName: '',
        t_surname: '',
        t_email: '',
        t_contactNumber: '',
        t_gender: '',
        t_no: '',              // Tenant's house/building number
        t_street: '',          // Tenant's street
        t_city: '',            // Tenant's city
        t_postcode: ''         // Tenant's postcode
    });
    
    const [token, setToken] = useState(null); // State for storing token

    useEffect(() => {
        const storedToken = localStorage.getItem('access_token');
        if (storedToken) {
            setToken(storedToken);
    
            // Decode the token to check its expiration time
            const tokenExpiration = decodeTokenExpiration(storedToken);
            const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
            
            if (tokenExpiration <= currentTime) {
                alert('Session expired. Please sign in again.');
                window.location.href = '/signin'; // Redirect to sign page
            } else {
                // Set a timer to auto-logout when the token expires
                const timeToExpire = (tokenExpiration - currentTime) * 1000;
                setTimeout(() => {
                    alert('Session expired. Redirecting to Sign in.');
                    window.location.href = '/signin';
                }, timeToExpire);
            }
        } else {
            alert('You are not authenticated. Please log in again.');
            window.location.href = '/signin'; // Redirect to sign page
        }
    }, []); // Run once on component mount
    
    const decodeTokenExpiration = (token) => {
        // Function to decode the JWT and extract the expiration time (exp)
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        );
        const decodedToken = JSON.parse(jsonPayload);
        return decodedToken.exp; // exp contains the expiration time in seconds
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        console.log('Form Data:', formData);
    
        if (!token) {
            console.error('No token available for the request!');
            alert('You are not authenticated. Please log in again.');
            return;
        }
    
        // Concatenate address fields
        const completeAddress = `${formData.no} ${formData.address}`;
        const completePropertyAddress = `${formData.t_no} ${formData.t_street}, ${formData.t_city}, ${formData.t_postcode}`;
    
        try {
            const response = await fetch('https://app.trackmydocs.cloud/api/clients/clients/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,  // Include the JWT token here
                },
                body: JSON.stringify({
                    client_type: clientType,
                    c_title: formData.c_title,
                    given_name: formData.givenName,
                    surname: formData.surname,
                    email: formData.email,
                    contact_number: formData.contactNumber,
                    gender: formData.gender,
                    address: completeAddress,  // Send the concatenated address
                    city: formData.city,
                    postcode: formData.postcode,
                    property_address: completePropertyAddress,  // Send the concatenated property address
                    t_title: formData.t_title,
                    t_givenName: formData.t_givenName,
                    t_surname: formData.t_surname,
                    t_email: formData.t_email,
                    t_contactNumber: formData.t_contactNumber,
                    t_gender: formData.t_gender
                }),
            });

            const result = await response.json();
            console.log('Response:', result);

            if (response.ok) {
                alert('Client created successfully!');
                window.location.href = '/dashboard'; // Redirect to dashboard
            } else {
                alert(`Failed to create client: ${result.detail || JSON.stringify(result)}`);
            }
        } catch (error) {
            console.error('Error submitting the form:', error);
        }
    };

    return (
        <div className="dashboard-container">
            <DashboardHeader />
            <Sidebar />
            <div className="main-content">
                <div className="content-area">
                    <div className="new-client">
                        <h3>New Client - Landlord</h3>
                        <form className="new-client-form" onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label htmlFor="clientType">Client Type</label>
                                <select 
                                    id="clientType" 
                                    value={clientType} 
                                    onChange={(e) => setClientType(e.target.value)}
                                >
                                    <option value="Person">Person</option>
                                    <option value="Organisation">Organisation</option>
                                    <option value="Business">Business</option>
                                    <option value="Trust">Trust</option>
                                </select>
                            </div>
                            {clientType === 'Person' && (
                                <>
                                    <div className="form-group">
                                        <label htmlFor="c_title">Title:</label>
                                        <input 
                                            type="text" 
                                            id="c_title" 
                                            name="c_title" 
                                            value={formData.c_title}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="givenName">Given Name:</label>
                                        <input 
                                            type="text" 
                                            id="givenName" 
                                            name="givenName" 
                                            value={formData.givenName}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="surname">Surname:</label>
                                        <input 
                                            type="text" 
                                            id="surname" 
                                            name="surname"
                                            value={formData.surname}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="email">Email:</label>
                                        <input 
                                            type="email" 
                                            id="email" 
                                            name="email"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="contactNumber">Contact Number:</label>
                                        <input 
                                            type="text" 
                                            id="contactNumber" 
                                            name="contactNumber"
                                            value={formData.contactNumber}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Gender:</label>
                                        <label>
                                            <input 
                                                type="radio" 
                                                name="gender" 
                                                value="Male" 
                                                checked={formData.gender === 'Male'}
                                                onChange={handleInputChange}
                                            /> Male
                                        </label>
                                        <label>
                                            <input 
                                                type="radio" 
                                                name="gender" 
                                                value="Female"
                                                checked={formData.gender === 'Female'}
                                                onChange={handleInputChange}
                                            /> Female
                                        </label>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="No">No:</label>
                                        <input 
                                            type="text" 
                                            id="no" 
                                            name="no" 
                                            value={formData.no}
                                            onChange={handleInputChange}
                                        />
                                    </div>                                    
                                    <div className="form-group">
                                        <label htmlFor="address">Street:</label>
                                        <input 
                                            type="text" 
                                            id="address" 
                                            name="address" 
                                            value={formData.address}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="city">City:</label>  {/* New City Field */}
                                        <input 
                                            type="text" 
                                            id="city" 
                                            name="city" 
                                            value={formData.city}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="postcode">Postcode:</label>  {/* New Postcode Field */}
                                        <input 
                                            type="text" 
                                            id="postcode" 
                                            name="postcode" 
                                            value={formData.postcode}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <h3>New Tenant</h3>
                                    <div className="form-group">
                                        <label htmlFor="t_title">Tenant Title:</label>  {/* New Property Address Field */}
                                        <input 
                                            type="text" 
                                            id="t_title" 
                                            name="t_title" 
                                            value={formData.t_title}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="t_givenName">Tenant Given Name:</label>  {/* New Property Address Field */}
                                        <input 
                                            type="text" 
                                            id="t_givenName" 
                                            name="t_givenName" 
                                            value={formData.t_givenName}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="t_surname">Tenant surname:</label>  {/* New Property Address Field */}
                                        <input 
                                            type="text" 
                                            id="t_surname" 
                                            name="t_surname" 
                                            value={formData.t_surname}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="t_email">Tenant Email:</label>  {/* New Property Address Field */}
                                        <input 
                                            type="text" 
                                            id="t_email" 
                                            name="t_email" 
                                            value={formData.t_email}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="t_contactNumber">Tenant Contact Number:</label>  {/* New Property Address Field */}
                                        <input 
                                            type="text" 
                                            id="t_contactNumber" 
                                            name="t_contactNumber" 
                                            value={formData.t_contactNumber}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label>Tenant Gender:</label>
                                        <label>
                                            <input 
                                                type="radio" 
                                                name="t_gender" 
                                                value="Male" 
                                                checked={formData.t_gender === 'Male'}
                                                onChange={handleInputChange}
                                            /> Male
                                        </label>
                                        <label>
                                            <input 
                                                type="radio" 
                                                name="t_gender" 
                                                value="Female"
                                                checked={formData.t_gender === 'Female'}
                                                onChange={handleInputChange}
                                            /> Female
                                        </label>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="t_No">Tenant No:</label>  {/* New Property Address Field */}
                                        <input 
                                            type="text" 
                                            id="t_No" 
                                            name="t_No" 
                                            value={formData.t_No}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="t_street">Tenant Street:</label>  {/* New Property Address Field */}
                                        <input 
                                            type="text" 
                                            id="t_street" 
                                            name="t_street" 
                                            value={formData.t_street}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="t_city">Tenant City:</label>  {/* New Property Address Field */}
                                        <input 
                                            type="text" 
                                            id="t_city" 
                                            name="t_city" 
                                            value={formData.t_city}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="t_postcode">Tenant Postcode:</label>  {/* New Property Address Field */}
                                        <input 
                                            type="text" 
                                            id="t_postcode" 
                                            name="t_postcode" 
                                            value={formData.t_postcode}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </>
                            )}
                            <div className="form-group">
                                <button type="submit" className="submit-button">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <DashboardFooter />
        </div>
    );
}

export default NewClient;
