import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DashboardHeader from '../components/DashboardHeader';
import Sidebar from '../components/Sidebar';
import DashboardFooter from '../components/DashboardFooter';
import './InvoiceManagement.css'; // Import the CSS for styling
import { Link } from 'react-router-dom';

const InvoiceManagement = () => {
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    const fetchInvoices = async () => {
      const token = localStorage.getItem('access_token');

      if (!token) {
        console.error('No JWT token found, redirecting to login.');
        // Handle authentication, redirect to signin if no token
        return;
      }

      try {
        // Fetch the invoices from the backend, including the summed amount
        const response = await axios.get('https://app.trackmydocs.cloud/api/invoices/invoice-table/', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setInvoices(response.data); // Store fetched invoices into state
      } catch (error) {
        console.error('Error fetching invoices:', error);
      }
    };

    fetchInvoices(); // Call the function on component mount
  }, []);

  return (
    <div className="im-container">
      <DashboardHeader />
      <Sidebar />
      <div className="im-main-content">
        <div className="im-content-area">
          
          {/* Invoice Management Features Div */}
          <div className="im-features">
            <h2 className="im-features-h2">Invoice Management</h2>
            <table className="im-feature-buttons">
              <tbody>
                <tr>
                  <td>Add Invoice</td>
                  <td>Admin Processing</td>
                </tr>
              </tbody>
            </table>
          </div>
          
          {/* Invoice Management Recent Invoices Div */}
          <div className="im-recent-invoices">
            <div className="im-recent-invoices-header">
              <h2>Recent Invoices</h2>
              <input
                type="text"
                className="im-search-bar"
                placeholder="Search invoices..."
              />
            </div>
            <table className="im-invoices-table">
              <thead>
                <tr>
                  <th>Invoice Number</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Our Reference</th>
                  <th>Client</th>
                </tr>
              </thead>
              <tbody>
                {invoices.length > 0 ? (
                  invoices.map((invoice) => (
                    <tr key={invoice.invoice_number}>
                      <td>
                        <Link to={`/disp-invoice/${invoice.invoice_number}`}>
                          {invoice.invoice_number}
                        </Link>
                      </td>
                      <td>£{invoice.amount ? invoice.amount.toFixed(2) : '0.00'}</td> {/* Ensure two decimal places */}
                      <td>{invoice.status}</td>
                      <td>{invoice.our_ref}</td>
                      <td>{invoice.client}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="5">No invoices found.</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <DashboardFooter />
    </div>
  );
};

export default InvoiceManagement;
